import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Menu from "../common/Menu";
import Lightbox from "../museum/Desktop/Lightbox";

const CemeteryGalleryMobile = ({ data }) => {
  const [lightbox, setLightbox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState(data.gallery[0]);

  function handleModal(id, img) {
    setCurrentIndex(id);
    setImage(img);
    setLightbox((prevState) => !prevState);
  }

  function handleNext() {
    if (currentIndex === data.gallery.length - 1) {
      setCurrentIndex(0);
      setImage(data.gallery[0]);
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setImage(data.gallery[currentIndex + 1]);
    }
  }

  function handlePrev() {
    if (currentIndex === 0) {
      setCurrentIndex(data.gallery.length - 1);
      setImage(data.gallery[data.gallery.length - 1]);
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setImage(data.gallery[currentIndex - 1]);
    }
  }

  function onClose() {
    setLightbox(false);
  }
  return (
    <div className="cemetery-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-12">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
            <Link to="/cemetery">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || `Back`}
                className="img-fluid cemetery-mobile-tatoo1"
                loading="eager"
                objectFit="contain"
              />
            </Link>
            <br />
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Logo"}
                  className="img-fluid cemetery-mobile-tatoo2"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
            <br />
            <center>
              <Link to="/cemetery">
                <GatsbyImage
                  image={data.cemeteryLogo.asset.gatsbyImageData}
                  alt={data.cemeteryLogo.asset.altText || "Cemetery Logo"}
                  className="img-fluid cemetery-mobile-tatoo3"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
            <br />
            <center>
              <GatsbyImage
                image={data.scary.asset.gatsbyImageData}
                alt={data.scary.asset.altText || "Scary"}
                className="img-fluid cemetery-mobile-tatoo4"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
        </div>
        <div className="clearfix" />
        <br />
        <br />
        <div className="row gallery-grid-top">
          {data.gallery.map((image, index) => (
            <div className="col-6 cemetry-grid" key={index}>
              <center>
                <div
                  className={`${
                    data.overlayAnimation ? "cemetry-grid-img" : ""
                  }`}
                  onClick={() => handleModal(index, image)}
                  style={{ cursor: "pointer" }}
                >
                  <GatsbyImage
                    image={image.image.asset.gatsbyImageData}
                    alt={image.image.alt || `Gallery Image`}
                    className="img-fluid zoom-img"
                    loading="lazy"
                    layout="fullWidth"
                  />
                  <div className="agile-b-wrapper">
                    <i className="fa fa-search-plus" aria-hidden="true" />
                  </div>
                </div>
              </center>
            </div>
          ))}
          <div className="clearfix"> </div>
        </div>
        {!!lightbox && (
          <Lightbox
            current={image}
            handleNext={handleNext}
            handlePrev={handlePrev}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default CemeteryGalleryMobile;
